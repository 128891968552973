.form-container {
    margin: auto;
    padding: 2rem;
    border-radius: 8px;
}
  
.form-title {
    text-align: center;
    color: #0c28c2;
}

.contact-form {
    max-width: 60%; 
    margin: auto;
    padding: 2rem;
    border-radius: 8px;
}

.contact-form h2 {
    text-align: center;
    color: #0033cc;
    margin-bottom: 1.5rem;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.form-group {
    flex: 1; 
    margin-right: 1rem;
}

.form-group:last-child {
    margin-right: 0;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #0033cc;
}

.form-group span {
    color: red;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 0.75rem; 
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-row-last-child {
    margin-bottom: 0 !important;
}
  

.error {
    color: red;
    font-size: 0.8rem;
    margin-top: 0.5rem;
}

.note {
    color: red;
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
}

.radio-group {
    display: flex;
    justify-content: space-between;
  }
  
  .radio-group input {
    margin-right: 0.5rem;
    width: 2%;
  }

  .radio-group label {
    margin: 0% !important;
  }

.checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;  
}

.checkbox-group input {
    width: 5% !important;
    margin-right: 0 !important;
}

.checkbox-group label {
    margin-bottom: 0 !important;
    display: inline-block;
}

.checkbox-group ~ .error {
    display: block;
    margin-top: 0; 
}

.submit-button {
    width: 20%; 
    padding: 0.75rem;
    background-color: #0033cc;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.submit-button:hover {
    background-color: #0033cc; 
}

.submit {
    text-align: center; 
}

@media (max-width: 768px) {
    .form-row {
        flex-direction: column;
    }

    .form-group {
        margin-right: 0;
        margin-bottom: 1rem;
    }
}
