
.cta-section {
    background-color: #002db3;
    color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .cta-content {
    max-width: 50%;
  }
  
  .cta-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .cta-description {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .cta-button {
    background-color: white;
    color: #002db3;
    padding: 10px 20px;
    font-size: 1.2rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #e0e4ff;
  }
  
  .cta-image img {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .cta-section {
      flex-direction: column;
      text-align: center;
    }
  
    .cta-content {
      max-width: 100%;
      margin-bottom: 20px;
    }
  
    .cta-image img {
      width: 80%;
    }
  }
  