@import url("primereact/resources/themes/lara-light-cyan/theme.css");

@font-face {
  font-family: 'Gilroy-Black';
  src: url('assets/fonts/Gilroy-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('assets/fonts/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Gilroy-Medium', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Gilroy-Medium', sans-serif;
}

p {
  font-family: 'Gilroy-Medium', sans-serif;
}



.layout-topbar {
  background: #0c28c2;
  padding: 10px 40px;
  border-bottom: 1px solid transparent;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0033cc;
  position: sticky;
  margin-bottom: 1px;
  height: 4rem;
}

.layout-topbar .logo {
  max-width: 80%;
  height: auto;
}

.topbar-right {
  flex: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

nav ul {
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-end;
}

nav ul li {
  position: relative;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  display: inline-block;
  font-size: 18px;
  transition: color 0.3s;
  position: relative;
}

nav ul li a:hover {
  color: #adadad;
}

nav ul li a.active {
  color: white;
  font-weight: bold;
}

nav ul li a.active::after {
  content: '';
  display: block;
  height: 10px;
  width: 100%;
  background-color: white;
  position: absolute;
  bottom: -14px;
  left: 0;
  border-radius: 5px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #ffffff;
  color: #000;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 3rem;
  width: 355px;
}

.dropdown-content a {
  color: #000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.login-button {
  padding: 5px 20px;
  background-color: #fff;
  color: #0033cc;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .layout-topbar {
    flex-direction: column;
    padding: 10px;
    height: auto;
  }

  nav ul {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  nav ul li {
    width: 45%;
    text-align: center;
  }

  .login-button {
    margin-left: 0;
    margin-top: 10px;
  }

  .topbar-left .logo {
    width: 100%;
    max-width: 150px;
  }
}

@media (max-width: 480px) {
  nav ul li {
    width: 100%;
  }

  nav ul {
    flex-direction: column;
  }

  .login-button {
    width: 100%;
  }

  nav ul li a.active::after {
    bottom: -3px;
  }
}

.layout-footer {
  padding: 20px;
  width: 100%;
  background-color: #000;
  color: #adadad;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  font-size: x-small;
}

.footer-left {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer-right div {
  margin-right: 15px; 
}

.footer-right div:last-child {
  margin-right: 0;
}