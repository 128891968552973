.mt-0 {
    margin-top: 0;
}

.mr-0 {
    margin-right: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.ml-0 {
    margin-left: 0;
}

.width-80per{
    width: 80% !important;
}

.carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: #f0f0f0; 
  }
  
  .carousel-content {
    display: flex;
    scroll-behavior: smooth;
    transition: transform 0.5s ease;
  }
  
  .carousel-item {
    min-width: 300px; 
    margin: 20px;
    padding: 20px;
    background-color: #fff; 
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .category {
    font-size: 20px;
    font-weight: bold;
  }
  
  .brand {
    font-size: 36px;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .description {
    font-size: 14px;
    color: #666;
  }
  
  .scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 10px;
  }
  
  .scroll-button.left {
    left: 10px;
  }
  
  .scroll-button.right {
    right: 10px;
  }
  