.section-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px;
    background-color: #0033cc;
    color: white;
    padding-top: 100px;
  }
  
  @media (min-width: 768px) {
    .section-container {
      flex-direction: row;
    }
  }
  
  .content-section {
    max-width: 100%;
    margin-bottom: 20px;
  }
  
  .button-outline {
    padding: 5px 10px;
    background: transparent;
    border: 1px solid white;
    color: white;
    border-radius: 25px;
    width: fit-content;
  }
  
  .main-heading, .text-heading {
    font-size: 32px;
    margin-top: 20px;
    font-family: 'Gilroy-ExtraBold', sans-serif;
  }
  
  .text-large {
    font-size: 18px;
  }
  

  
  .button-primary {
    padding: 10px 15px;
    background-color: white;
    color: #0033cc;
    border: none;
    cursor: pointer;
    font-size: 15px;
    border-radius: 4px;
  }
  
  .image-section img {
    width: 100%;
    height: auto;
    max-width: 80%;
  }
  
  @media (min-width: 768px) {
    .image-section img {
      max-width: 100%;
    }
  }
  
  .divider {
    border: none;
    border-top: 1px solid white;
    margin: 20px 0;
    width: 50%; 
  }
  
  .text-center {
    text-align: center;
  }
  
  .responsive-image {
    width: 80%;
    height: auto;
  }
  
  .benefits-section {
    display: flex;
    flex-direction: column;
    background-color: #e3e6fe;
    padding: 20px;
  }
  
  .benefit-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px 0px 20px;
  }
  
  @media (min-width: 768px) {
    .benefit-item {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  
  .benefit-description {
    width: 100%;
    padding: 20px;
  }
  
  @media (min-width: 768px) {
    .benefit-description {
      width: 55%;
      padding: 40px;
    }
  }
  
  .automation-section {
    text-align: center;
    padding: 60px 20px;
  }
  
  .text-heading {
    font-size: 2rem;
    color: #002db3;
    margin-bottom: 40px;
    font-family: 'Gilroy-ExtraBold', sans-serif;
  }
  
  .cards-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: stretch; 
  }
  
  .card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    flex: 1; 
    display: flex;
    flex-direction: column;
  }
  
  .icon {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
  }
  
  .color-white{
    color: #e3e6fe !important; 
  }
  
  .text-subheading {
    font-size: 1.5rem;
    color: #002db3;
    margin-bottom: 15px;
  }
  
  .text-normal {
    font-size: 1.5rem;
    color: #333;
    line-height: 1.6;
    margin: 0%; 
    margin-top: 40px;
  }

  .w-100{
    width: 100% !important;
  }
  
  @media (max-width: 768px) {
    .cards-container {
      flex-direction: column; 
      gap: 30px;
    }
  }
  
  @media (min-width: 768px) {
    .responsive-image {
      width: 40%;
      margin-bottom: 0;
    }
  }
  