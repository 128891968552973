.pricing-container {
  text-align: center;
  padding: 2rem;
  background-color: #f5f7fa;
}

.pricing-title {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #0c28c2;
}

.pricing-options {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
  align-items: stretch;
}

.pricing-card {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: left; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.align-bottom{
  margin-top: auto;
}

.button-container{
  text-align: center;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.standard-plan {
  color: #0c28c2;
  border: 1px solid #0c28c2;
}

.premium-plan {
  background-color: #0c28c2;
  color: white;
}

.pricing-card h2 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.pricing-card ul {
  list-style-type: disc; 
  padding-left: 20px; 
  margin-bottom: 1.5rem;
}

.pricing-card ul li {
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.pricing-card p {
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.contact-button {
  background-color: #0c28c2;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  margin-top: auto;
}

.contact-button:hover {
  background-color: #163d80;
}

.premium-plan .contact-button {
  background-color: white;
  color: #0c28c2;
}

.standard-plan hr{
  border-top: 1px solid #0c28c2 !important;
}

.premium-plan .contact-button:hover {
  background-color: #f0f0f0;
}

.contact-info {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #0c28c2;
}

.final-contact-button {
  background-color: #0c28c2;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 4px;
  cursor: pointer;
}

.final-contact-button:hover {
  background-color: #163d80;
}

@media (max-width: 768px) {
  .pricing-options {
    flex-direction: column;
    align-items: center;
  }
}
