

.carousel-container {
    padding: 50px;
    background-color: #f0f0f0;
    border-radius: 8px;
  }
  
  .title {
    font-size: 2em;
    margin-bottom: 20px;
    align-items: center;
  }
  
  .categories {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .category {
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .category.active {
    background-color: #007bff; /* Active category color */
    color: white;
  }
  
  .brand {
    font-size: 1.5em;
    margin: 10px 0;
  }
  
  .description {
    font-size: 1em;
    line-height: 1.5;
  }

  .slider-image{
    width: 10%;
    height: 10%;
  }
  